<template>
  <div class="page-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'PublicTemplate',
    components: {},
    data () {
      return {}
    }
  }
</script>

<style scoped>
</style>
